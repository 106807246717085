import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';

export default function Policies() {
	const {id} = useParams();
	const _link = useRef(null);
	const [opened, setOpened] = useState(false);

	return (
		<div>Comercializadora Kiam S de R.L de C.V

Conforme a los desarrollos de Aplicaciones para PlayStore y APP Store que la empresa realiza tomar el siguiente punto de las Políticas de Privacidad

Política de Privacidad

De conformidad en lo dispuesto por la ley federal de protección de datos Personales en posesión de Particulares, su reglamento y la normatividad relacionada (“La legislación de Datos Personales”) se emite el presente aviso de Privacidad en los siguientes términos:

1. Identidad, domicilio y servicios del Responsable.

Comercializadora Kiam S de R.L de CV (Desarrollador) y Vibe KM S de RL de CV el “Responsable” o “Nosotros” indistintamente, mejor conocido como Vibe es el responsable de la obtención, uso, divulgación y almacenamiento de los datos personales ( el “Tratamiento”) de los usuarios (“Usted”, el “Titular” o “Usuario”), mismos que se recaban como resultado de los servicios que Vibe KM S de RL de CV presta a través de su página de Internet (el “Sitio”), o bien a través de nuestra aplicación móvil o de algún otro medio electrónico (la “App”), con domicilio en Privada de la cumbre 23, Huixquilucan, Estado de México, 52774.

Vibe es una Plataforma virtual desarrollada por Kiam que presta servicios de tecnología. En dicha plataforma se exhiben productos de diversos establecimientos de comercio a Vibe para que sean adquiridos por los consumidores/usuarios (los “Servicios”).

2. Datos Personales recabados y Tratados por el responsable.

Para las finalidades indicadas en el presente Aviso de Privacidad, el Responsable trata las siguientes categorías de datos personales:

El responsable no recaba datos personales sensibles.

3. Finalidades de Tratamiento.

Trataremos los datos personales para las siguientes finalidades primarias y necesarias:

    Ordenar, catalogar, clasificar, dividir o separar y almacenar los Datos personales dentro de los sistemas y archivos de Vibe.
    Creación y administración de la cuenta del usuario.
    Mantener, desarrollar y controlar de la relación comercial entre el Usuario y Vibe.
    Proporcionar al Usuario información necesaria, a través de la pagina de internet y aplicación, sobre los productos de los oferentes, para formalizar la relación de consumo de dichos productos.
    Realizar procesos al interior de Vibe, con fines de desarrollo operativo y/o de administración de sistemas.
    Prestar los servicios de Vibe y dar seguimiento de acuerdo con las necesidades particulares del usuario, con el fin de brindar los servicios y productos adecuados para cubrir sus necesidades específicas.
    Realizar el envío de información de novedades, noticias, boletines en la aplicación.
    Llevar un registro histórico de la información, con fines de satisfacción al usuario desarrollando análisis sobre los intereses y necesidades; brindando de esta manera un mejor servicio.
    Realizar estrategias de mercado mediante el estudio del comportamiento del usuario frente a las ofertas y con ello mejorar en su contenido, personalizando presentación y servicio.
    Realizar encuestas de satisfacción y ofrecimiento por reconocimiento de beneficios propios de nuestro programa de lealtad y servicios postventa, para calificar el servicio y la atención por medio de los canales dispuestos para ello.
    Adelantar las actividades necesarias para gestionar las solicitudes, quejas reclamos de los Usuarios de la empresa o un tercero; y direccionarlos a las áreas responsables de emitir las respuestas correspondientes.
    Presentar reportes ante las autoridades de inspección, vigilancia y control y tramitar los requerimientos realizados por entidades administrativas o judiciales.
    Y usos administrativos, comerciales y de publicidad que se establezcan en los acuerdos suscritos con los clientes Titulares de la información.
    Gestión contable, económica, fiscal y administrativa de clientes.
    Tener acceso a centrales de riesgo para conocer los estados financieros de los Usuarios.
    Transferencia o transmisión de datos nacional o internacionalmente a proveedores con los que Vibe desarrolle actividades en cumplimiento de su objeto social. Asimismo, se podrá hacer transferencia a los aliados estratégicos de la empresa para que ejecuten actividades de marketing, publicidad y promociones asociadas con el objeto.


4. Opciones y medios que el responsable le ofrece para limitar el uso o divulgación de sus daos personales.

En todos aquellos casos legalmente procedentes, usted podrá limitar el uso o divulgación de sus datos personales dirigiendo la solicitud correspondiente a nuestro departamento de Datos Personales, siguiendo el procedimiento a que se refiere el numeral 6 del presente Aviso de Privacidad.

5. Transferencia de datos Personales.

Sus datos personales podrán ser transferidos dentro del territorio nacional, conforme a lo siguiente:

    A autoridades, organismos o entidades gubernamentales en cumplimiento a o en relación con las obligaciones. Contempladas en la legislación aplicable al responsable, sus subsidiarias y/o afiliadas, así como el cumplimiento de requerimientos efectuados a las mismas.
    A autoridades, organismos o entidades gubernamentales, cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de un derecho del Responsable, sus subsidiarias y/o afiliadas en un proceso judicial.
    A compañías y agentes de seguro, cuando la transferencia sea necesaria por virtud de un contrato celebrado o por celebrar en interés del titular, mismo que tendrá como objeto estar en posibilidades de ofrecerle seguros personalizados y descuentos en la adquisición de seguros, en caso de que así lo solicite.
    A compañías afiliados y subsidiarias del responsable, con finalidades de valoración y análisis de perfiles de los usuarios, resguardo centralizado de la información y para fines estadísticos y de registro histórico de usuarios del responsable y sus subsidiarias y/o afiliadas.
    A terceros derivado de una restructura corporativa del Representante, incluyendo la fusión, consolidación venta, liquidación o transferencia de activos.
    A terceros cuando la transferencia sea necesaria para mantenimiento o cumplimiento de una relación jurídica entre responsable y el titular.
    A terceros cuando la transferencia sea necesaria por virtud de un contrato celebrado o por celebrar en interés del titular, por responsable y un tercero.
    Con base en los demás supuestos establecidos en la Legislación de datos Personales que no requieren de su consentimiento.


Las transferencias antes mencionadas no requieren de su consentimiento conforme a la Legislación de Datos Personales.

Para mayor información contacto@ckiam.com.mx</div>
	);
}
